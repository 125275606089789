<template>
  <div class="patE">
    <div class="banner">
      <div class="banner-box">
        <div class="banner-text">
          <p class="title animate__animated animate__fadeInLeftBig">深蓝·高价值科技企业识别导航系统</p>
          <p class="sub-title animate__animated animate__fadeInRightBig">
            剖析聚焦产业图谱结构<br/>
            关注获取产业节点与地区动态热度趋势<br/>
            精准、超前锁定具有发展潜力的中小企业<br/>
          </p>
          <div class="btn-container">
            <!-- <p class="login-btn" @click="handleLogin">{{ isLogin ? '立即使用' : '立即登录' }}</p>
            <a class="apply-btn" href="#trial">申请试用</a> -->
            <!-- <p class="login-btn">准备上线</p> -->
          </div>
        </div>
      </div>
    </div>
    <section class="animate__animated animate__fadeIn">
      <div class="tec">
        <div class="tec-wrap">
          <div class="tec-content">
            <p class="title">产业图谱</p>
            <p class="desc">
              全局俯瞰产业发展<br/>
              整体把握产业链结构<br/>
              聚焦产业节点潜力地区<br/>
            </p>
            <!-- <p class="main-button" @click="experience">立即体验</p> -->
          </div>
          <img class="tec-img" src="https://images.zzt.com.cn/ks/2023/04/25/industry-map.png" width="810">
        </div>
      </div>
      <div class="track">
        <div class="track-wrap">
          <img class="track-img" src="https://images.zzt.com.cn/ks/2023/05/05/technology-navigation.png" width="810">
          <div class="track-content">
            <p class="title">中知科创导航</p>
            <p class="desc">
              中知数通特色高价值科技企业标签<br/>
              导航系统
            </p>
            <!-- <p class="main-button" @click="experience">立即体验</p> -->
          </div>
        </div>
      </div>
      <div class="mark">
        <div class="mark-wrap">
          <div class="mark-content">
            <p class="title">热度趋势</p>
            <p class="desc">
              新闻媒体热度<br/>
              资本市场热度<br/>
              产业政策热点
            </p>
            <!-- <p class="main-button" @click="experience">立即体验</p> -->
          </div>
          <img class="mark-img" src="https://images.zzt.com.cn/ks/2023/04/25/heat-trend.png" width="810">
        </div>
      </div>
    </section>
    <trial id="trial"/>
  </div>
</template>
<script>
import trial from '@/components/trial.vue'
import { mapState } from 'vuex'

export default {
  components: { trial },
  computed: {
    ...mapState(['isLogin'])
  },
  methods: {
    experience () {
      console.log('experience deepBlue')
    },
    handleLogin () {
      console.log('深蓝还没法登录')
    }
  }
}
</script>
<style lang='scss' scoped>
.main-button {
  @include Button(140px, 36px, #4088EB, 14px, #FFFFFF);
  border: Translate(1px) solid #fff;
  &:hover {
    background: #4299eb;
    color: #fff;
    border: Translate(1px) solid #fff;
  }
}
.patE {
  p {
    margin: 0;
  }
  .banner {
    width: 100%;
    min-width: $container-width;
    height: Translate(500px);
    background: url("https://images.zzt.com.cn/ox/2023/04/27/deepblue_bg.png") no-repeat;
    background-position: center center;
    background-size: cover;
    margin: 0 auto;
    // background-image: linear-gradient(-83deg, #68BAFF 0%, #4088EB 100%);
    &-box {
      width: $container-width;
      margin: 0 auto;
      position: relative;
      height: 100%;
    }
    &-text {
      text-align: left;
      color: #fff;
      .title {
        position: absolute;
        top: Translate(110px);
        left: 0;
        color:#333;
        font-size: Translate(40px);
        font-weight: 500;
      }
      .sub-title {
        position: absolute;
        top: Translate(180px);
        left: 0;
        color:#666;
        font-size: Translate(16px);
        line-height: Translate(34px);
      }
      .btn-container{
        width: Translate(338px);
        position: absolute;
        top: Translate(340px);
        left: 0;
        display: flex;
        justify-content: space-between;
        .login-btn {
          @include Button(160px, 40px, #4088EB, 14px, #fff);
          // border: Translate(1px) solid #4088EB;
          // &:hover {
          //   background: #337ee5;
          // }
          opacity: .5;
          cursor: no-drop;
        }
        .apply-btn {
          @include Button(160px, 40px, #fff, 14px, #4088EB);
          border: Translate(1px) solid #4088EB;
          &:hover {
            background: #4088EB;
            color: #fff;
            border: 1px solid #4088EB;
          }
        }
     }
    }
  }
  section {
    background-color: #fff;
    img {
      font-size: 0;
    }
    .tec, .track, .mark {
      display: flex;
      align-items: center;
      justify-content: center;
      height: Translate(558px);
      min-width: $container-width;
      max-width: Translate(1920px);
      margin: 0 auto;
      &-wrap {
        display: flex;
        width: $container-width;
        justify-content: space-between;
        align-items: center;
      }
      &-content {
        width: Translate(240px);
        text-align: left;
        .title {
          font-size: Translate(32px);
          font-weight: 500;
          color:#333;
          margin-bottom: Translate(20px);
        }
        .desc {
          font-size: Translate(16px);
          color:#666;
          line-height: Translate(34px);
          margin-bottom: Translate(40px);
        }
      }
    }
    .tec {
      // background: url("https://images.zzt.com.cn/ox/2022/12/12/tec-bg.png") no-repeat;
      width: 100%;
      background-position: center center;
      background-size: cover;
    }
    .track {
      // background: url("https://images.zzt.com.cn/ox/2022/12/23/track-bg.png") no-repeat;
      width: 100%;
      background-position: center center;
      background-size: cover;
      max-width: unset;
    }
  }
}
</style>
